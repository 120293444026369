import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

const query = graphql`
  query {
    paypalButton: file(absolutePath: { regex: "/paypal-button-donate/" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const PayPalButton: FC = () => {
  const data = useStaticQuery(query)
  const img = data.paypalButton.childImageSharp.fluid
  return (
    <div>
      <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="business" value="norhaeg@gmail.com" />
        <input type="hidden" name="currency_code" value="EUR" />
        <input
          type="image"
          // src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_LG.gif"
          src={img.src}
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Spenden mit dem PayPal-Button"
        />

        {
          // Dont allow cookies for now
          /* <img
          alt=""
          src="https://www.paypal.com/de_DE/i/scr/pixel.gif"
          width="1"
          height="1"
        /> */
        }
      </form>
    </div>
  )
}
